<template>
  <component :is="'PageWithColoredCenteredBox'">
    <div class="page-centered-box page-before-starting-test">
      <h2 class="h-2">{{ $t('page-before-starting-test.title') }}</h2>
      <p class="margin-top-s text-center" v-html="$t('page-before-starting-test.text')"></p>
      <img class="margin-top-s img-responsive" src="@/assets/img/card.svg" alt="Card"/>
      <button class="btn primary-bg body-bold small-btn margin-top-l"
              v-on:click="this.$changePageWithAnalyticsStep(this.$store, 'PageKeepThem')">
        {{ $t('page-before-starting-test.button-text') }}
      </button>
    </div>
  </component>
</template>

<script>
import PageWithColoredCenteredBox from "@/components/pages/generic/PageWithColoredCenteredBox";


export default {
  components: {PageWithColoredCenteredBox},
};
</script>
